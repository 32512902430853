<script>
import { mapActions } from 'vuex';
import snackbarMixin from '@/mixins/snackbarMixin.js';

export default {
  name: 'App',
  mixins: [snackbarMixin],
  created() {
    if (window.localStorage.getItem('session_id')) {
      this.getLoginUser();
    }
  },
  methods: {
    ...mapActions('app', ['getLoginUser']),
  },
};
</script>

<template>
  <v-app id="main-background">
    <v-container id="container">
      <v-row>
        <router-view />
      </v-row>
    </v-container>
    <notifications
      ref="notifications"
      position="bottom right"
      :close-on-click="false"
    >
      <template #body="props">
        <div :class="snackbarType(props.item)">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-row>
                <v-col class="text-h6 text-left" cols="6">
                  {{ props.item.title }}
                </v-col>

                <v-col class="text-right" cols="6">
                  <v-icon
                    v-if="['error', 'warning'].includes(props.item?.type)"
                    class="text-white mr-1"
                    @click="copySnackBarText"
                  >
                    mdi-content-copy
                  </v-icon>
                  <v-icon class="text-white" @click="props.close">
                    mdi-close
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="pt-0">
              <div id="snackbar" v-html="props.item.text" />
            </v-col>
          </v-row>
        </div>
      </template>
    </notifications>
  </v-app>
</template>

<style lang="scss">
body {
  font-family: 'Hahmlet', 'Helvetica', 'sans-serif';

  .theme--light.v-text-field--outlined > .v-input__control > .v-input__slot {
    background: #F1F4F8;
    background-image: initial;
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
}
}

.no-text-transform {
  text-transform: none !important;
}

.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__prefix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) .v-text-field__suffix,
.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    padding: 4px 0px 4px 6px;
}

$body-font-family: "Nunito", Helvetica, sans-serif;
@font-face {
    font-family: "Nunito";
    src: url('/assets/fonts/Nunito-Medium.ttf');
}

.v-application {
  font-family: $body-font-family !important;
  .primary--text {
    caret-color: '#ec7077'!important;
    color: '#ec7077'!important;
  }
}

.headline,
.title,
.subheading{
  font-family: $body-font-family !important;
}

#main-background {
  background-color: whitesmoke;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}

.rbx-notification {
  margin: 0 5px 5px;
  padding: 10px;
  font-size: 12px;
  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;

  &__success {
    background: #68cd86;
    border-left-color: #42a85f;
  }

  &__warning {
    background: #ffb648;
    border-left-color: #f48a06;
  }

  &__error {
    background: #e54d42;
    border-left-color: #b82e24;
  }

  &__info {
    background: #44a4fc;
  border-left-color: 5px solid #187fe7;
  }
}
</style>
